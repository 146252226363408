/**
 * Bio
 */

.avatar {
  vertical-align: top;
  width: 150px;
  border-radius: 75px;
  display: inline-block;
  margin-right: 0.5rem;
  shape-outside: border-box;
  shape-margin: 0.5rem;

  @include media-query($on-laptop) {
    shape-outside: none;
    shape-margin: unset;
    margin-right: 2rem;
  }
}


.home .post-link,
.archive-page .post-link {
  font-size: 1.125rem;
  font-weight: normal;
}