@charset "utf-8";

@import "research-portfolio-variables";

$post-content-font-size: 18px;


// Width of the content area
$content-width:    900px;

$on-palm:          600px;
$on-laptop:        800px;

// Import partials from the `research-portfolio` theme.
@import "research-portfolio";
@import "gallery";

/* Custom Fonts */
/**
 * @license
 *
 * Font Family: Rowan
 * Designed by: Inga Plönnigs
 * URL: https://www.fontshare.com/fonts/rowan
 * © 2021 Indian Type Foundry
 *
 * Font Styles:
 * Rowan Variable(Variable font)
 * Rowan Variable Italic(Variable font)
 * Rowan Light
 * Rowan Light Italic
 * Rowan Regular
 * Rowan Italic
 * Rowan Medium
 * Rowan Medium Italic
 * Rowan Semibold
 * Rowan Semibold Italic
 * Rowan Bold
 * Rowan Bold Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 300.0 to 700.0)

*/

@font-face {
  font-family: 'Rowan-Variable';
  src: url('../fonts/Rowan-Variable.woff2') format('woff2'),
  url('../fonts/Rowan-Variable.woff') format('woff'),
  url('../fonts/Rowan-Variable.ttf') format('truetype');
  font-weight: 300 700;
  font-display: swap;
  font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 300.0 to 700.0)

*/

@font-face {
  font-family: 'Rowan-VariableItalic';
  src: url('../fonts/Rowan-VariableItalic.woff2') format('woff2'),
  url('../fonts/Rowan-VariableItalic.woff') format('woff'),
  url('../fonts/Rowan-VariableItalic.ttf') format('truetype');
  font-weight: 300 700;
  font-display: swap;
  font-style: italic;
}


/* */

:root {
  --bs-body-font-family: "Rowan-Variable", "Georgia", serif;
  --small-font-size: 0.875rem;
  --theme-color: hsl(72.88, 66.53%, 48%);
  --theme-color-light: hsl(72.88, 66.53%, 53%);
  --theme-color-lighter: hsl(72.88, 66.53%, 58%);
  --theme-color-faint: hsl(72.88, 66.53%, 85%);
  --theme-color-dark: hsl(72.88, 66.53%, 43%);
  --theme-color-darker: hsl(72.88, 66.53%, 38%);
  --theme-color-darkest: hsl(72.88, 66.53%, 33%);
  --theme-color-darkestest: hsl(72.88, 66.53%, 28%);
}

i, em {
  font-family: "Rowan-VariableItalic", "Georgia", serif;
}

/* Header customizations */

.site-title {
  font-size: 21px;
  font-weight: 900;
  letter-spacing: 0;
  @include media-query($on-laptop) {
    margin-left: 0;
    font-size: 24px;
    line-height: $spacing-unit * 2;
  }


  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
}

/* Override initial hiding which we applied to prevent flash of unstyled content */
html {
  visibility: visible;
  opacity: 1;
}