.gallery-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 48px;
  .gallery-image-wrapper {
    width: 300px;
    height: 300px;
    //height: calc(100% / 3);
    a {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: 0.3s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}