/**
 * Site header
 */
.site-header {
  /*border-bottom: 1px solid $grey-color;*/
  background-color: var(--theme-color);
  color: white;
  // Positioning context for the mobile navigation icon
  position: relative;
  margin-bottom: 1rem;
  @include media-query($on-tablet) {
    margin-bottom: 2rem;
  }
  .wrapper {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    @include media-query($on-tablet) {
      flex-flow: row wrap;
    }
  }
  .nav-link {
    color: var(--bs-nav-link-color);
    transition: none;
    border-color: transparent;
    &:hover {
      background-color: var(--theme-color-dark);
    }
    &:visited {
      color: var(--bs-nav-link-color)
    }
  }
}

.site-title {
  font-size: 21px;
  font-weight: 900;
  line-height: 2.0rem;
  margin-bottom: 0;

  @include media-query($on-laptop) {
    margin-left: 0;
    font-size: 24px;
    line-height: $spacing-unit * 2;
  }
  text-decoration: none;
  &:visited {
    color: inherit;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
}

.site-nav {
  line-height: 2rem;
  margin: 0 auto;

  @include media-query($on-laptop) {
    margin: 0;
    margin-left: auto;
    line-height: $spacing-unit * 2;
  }
  .menu-icon {
    display: none;
  }

  .page-link {
    color: inherit;
    -webkit-text-decoration: none;
    text-decoration: none;
    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

}


/**
 * Page content
 */
.page-content {
  margin: 1rem 0;
  overflow-x: clip;
  /* Our full bleed hack can cause overflow: https://css-tricks.com/full-bleed/ */

  @include media-query($on-tablet) {
    margin: 2rem 0;
  }
}

.restrict-width {
  max-width: 650px;
}

.limit-width {
  max-width: 900px;
  margin: 0 auto;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  justify-content: center;
}

.almost-full-bleed {
  max-width: 900px;
  width: 100vw;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 1.5dppx) {
  footer {
    border-width: 0.5px;
  }
}

/**
 * Site footer
 */
footer,
footer p {
  font-weight: 300;
  color: $grey-color-mid-light;
}

.site-footer {
  border-top: 1px solid var(--light-background-color);
  padding: 2rem 0;
}

.branded-title,
.branded-title:visited {
  font-weight: 900;
  letter-spacing: 0.2px;
  -webkit-text-decoration: none;
  text-decoration: none;
}

footer {
  a:not(.btn),
  a:not(.btn):visited {
    color: $grey-color-mid-light;
    border-color: lighten($grey-color-mid-light, 10%);
  }
  a:not(.btn):hover {
    color: $grey-color-dark;
    border-color: lighten($grey-color-dark, 25%);
  }
}

.footer-heading {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.social-media-list {
  ion-icon {
    margin-right: 8px;
    display: inline-block;
    width: 15px;
  }
}



