// Define defaults for each variable.

$spacing-unit:     24px !default;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-mid-light: lighten($grey-color, 20%);
$grey-color-dark:  darken($grey-color, 25%);


// Width of the content area
$content-width:    900px !default;

$on-phone: 300px !default;
$on-tablet: 768px !default;
$on-laptop: 992px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}