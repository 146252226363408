.post-meta {
  font-size: var(--small-font-size);
  letter-spacing: 2px;
  text-transform: uppercase;
  @include media-query($on-laptop) {
    float: right;
  }
}

.permalink, .permalink:visited {
  text-decoration: none;
  color: var(--bs-secondary-color);
  border-bottom: none;
  display: inline-block;
  padding: 0px 5px 0 5px;
}

header:hover .permalink {

  text-decoration: none;
}

.permalink:hover {

}

article.full-post {
  h2 {
    font-size: 1.5rem;
  }
  header a {
    text-decoration: none;
    letter-spacing: .4px;
  }
  .permalink {
    display: none;
  }
}
sup {
  line-height: 0px;
}

sup a:link {
  padding: 3px;
}

.blog-list article {
  margin-bottom: 3rem;
}

article.link-post {
  header h2 {
  }
}

article.project img {
  background-color: #ccc;
}

.footnotes {
  padding-left: 1rem;
  padding-top: 1rem;
  margin-top: 4rem;
  font-size: var(--small-font-size);
}

.footnotes::before {
  content: "";
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  height: 1px;
  display: block;
  background-color: lightgray;
  margin-bottom: 1rem;
}



article {
  ol, ul {
    padding-left: .75rem;
  }

  li > ul, li > ol {
    padding-left: .5rem;
  }


  @include media-query($on-laptop) {
    pre {
      //max-width: initial;
    }
  }

  blockquote {
    box-sizing: border-box;
  }

  hr {
    &::after {
      content: "···";
      letter-spacing: 2em;
    }
    margin: 2em auto 4em auto;
    text-align: center;
    border-color: var(--light-text-color);
    border-width: 0;
    border-style: dotted;
  }

  header {
    margin-bottom: 1rem;
    .post-meta {
      font-size: var(--small-font-size);
      font-weight: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
      float: none;
    }


  }

  .video {
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  .article-image {

    img {
      margin: 0 auto;
      margin-bottom: calc($spacing-unit/2.0);
      width: 100%;
      object-fit: contain;
      background-color: var(--light-background-color);
      max-height: 75vh;
    }
    figcaption p {
      font-size: var(--small-font-size);
      color: var(--text-color);
    }
  }

  .full-width figcaption {
    padding: 0 var(--bs-gutter-x);
  }

  .article-multiimage {
    margin-bottom: 2rem;

    .images {
      display: flex;
      flex-direction: column;
      //grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      //grid-gap: 1rem;
      align-items: start;
      @include media-query($on-tablet) {
        & {
         flex-direction: row;
          gap: .5rem
        }
      }

      img {
        margin: 0 auto;
        margin-bottom: calc($spacing-unit/2.0);
        object-fit: contain;
        background-color: var(--light-background-color);
        max-height: 75vh;
      }
    }
    figcaption p {
      font-size: var(--small-font-size);
      color: var(--text-color);
    }
  }


}

.post-content h2,
.project-content h2,
article.page h2 {
    text-transform: uppercase;
    letter-spacing: 2px;
  }



/**
 * Post list
 */

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: 0.5rem;
  }
}

