// Gallery

.project-images {
  margin-bottom: 1rem;
  background-color: $grey-color-light;
}

.project-images img {
  transition: 0.3s;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}

.project-images img:hover {
  transform: scale(1.05);
}

.project-images a {
  display: block;
}

.project-images .hero {
  width: 100%;
  min-height: 200px;
  height: 40vh;
  overflow: hidden;
}

.thumbnails {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  min-height: 100px;
  height: 20vh;
  max-width: unset;
}

.thumbnails > * {
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;
}

.thumbnails > * > * {
  width: 100%;
  height: 100%;
}

.thumbnails img {
  width: 100%;
  height: 100%;
}

// Quick Links

.quick-links {
  margin: 0;
  padding: 0;
  list-style: none;

  @include media-query($on-phone) {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    justify-content: left;
  }

}

