/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}


/**
 * Basic styling
 */
:root {
  --bs-body-color: #111;
  --bs-light-bg-subtle: #eee;
  --light-text-color: #333;
  --lighter-text-color: #828282;
  --bs-body-bg: #fdfdfd;
  --theme-color: red;
  --light-background-color: #eee;
  --text-brand-color: var(--theme-color);
  --link-color: var(--theme-color-darker);
  --link-decoration-color: var(--theme-color-lighter);
  --link-color-hover: var(--theme-color-darker);
  --link-border-color: rgba(255, 255, 255, .3);
  --bs-body-line-height: 1.5;
  --bs-body-font-size: 16px;
  --bs-body-font-weight: 400;
  --bs-body-font-family: "Georgia", serif;
  --bs-light-rgb: 240, 240, 240;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;

  @include media-query($on-laptop) {
    --bs-body-font-size: 17px;
  }
}

.navbar {
  --bs-navbar-padding-y: 0;
  --bs-navbar-brand-padding-y: 0;
}

.navbar-nav {
  --bs-nav-link-color: white;
  --bs-nav-link-hover-color: #eaeaea;
}

.navbar a:hover {
  background-color: transparent;
}

::selection {
  background-color: var(--theme-color-faint);
}

/*
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: hsl(120, 2%, 9%);
    --light-background-color: hsl(120, 2%, 20%);
    --text-color: #ccc;
    --light-text-color: #aaa;

    --heading-color: #dee2e6;
    --hr-color: rgba(255, 255, 255, .1);
    --code-color: rgba(212, 73, 80, .85);
    --code-snippet-border: rgba(255, 255, 255, .1);
    --neutral-accent: #828282;
  }
}*/


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: 1rem;
}


section {
  margin-bottom: 1rem;
  /* This ends up hiding hanging bullets
  overflow: auto;*/
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: var(--small-font-size);
}



/**
 * Lists
 */


li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
}

h1 {
  font-size: 1.5rem;
  font-weight: 700;
}


h2, .h2 {
  font-size: 1.25rem;
  font-weight: 650;
}
p {
  margin: 0 auto 1rem auto;
}

h3, .h3 {
  font-size: 1.1rem;
  line-height: 2rem;
  font-weight: 700;
}

h4, h5, h6 {
  font-size: 1rem;
  margin-bottom: .5rem;
  font-weight: 700;
}

b, strong {
  font-weight: 600;
}

.text-secondary {
  color: var(--lighter-text-color) !important;
}


/**
 * Links
 */

a:not(.btn) {
  color: var(--theme-color-darkest);
  text-decoration: none;
  border-color: var(--theme-color-dark);
  border-width: 0 0 1px 0;
  border-style: none none solid none;
  background-color: inherit;

  &.link-secondary {
    border-color: #a2a2a2;
  }

  &:visited {
    color: var(--theme-color-darkest);
    border-color: var(--theme-color-light);
  }

  &:hover {
    text-decoration: none;
    background-color: var(--theme-color-faint);
  }
}

.btn {
  padding: .25rem .5rem;
}

.btn-primary, .btn:visited  {
  --bs-btn-color: white;
  --bs-btn-bg: var(--theme-color);
  --bs-btn-border-color: var(--theme-color);
  --bs-btn-hover-bg: var(--theme-color-light);
  --bs-btn-hover-border-color: var(--theme-color-light);
  --bs-btn-active-border-color: var(--theme-color-dark);
  --bs-btn-active-color: white;
  --bs-btn-active-bg: var(--theme-color-dark);
}

.btn-outline-primary, .btn-outline-primary:visited {
  --bs-btn-color: var(--theme-color);
  --bs-btn-border-color: var(--theme-color-light);

  --bs-btn-hover-bg: var(--theme-color-faint);
  --bs-btn-hover-color: var(--theme-color);
  --bs-btn-hover-border-color: var(--theme-color-lighter);

  --bs-btn-active-bg: var(--theme-color-dark);
  --bs-btn-active-border-color: var(--theme-color-dark);
  --bs-btn-active-color: white;
  --bs-btn-active-bg: var(--theme-color-dark);

  --bs-btn-focus-shadow-rgb: 255, 255, 255
}


article li [href^="http"]:not([href*="nickwalker.us"],a.btn)::after,
article p [href^="http"]:not([href*="nickwalker.us"],a.btn)::after,
article h1 [href^="http"]:not([href*="nickwalker.us"],a.btn)::after,
article h2 [href^="http"]:not([href*="nickwalker.us"],a.btn)::after{
  background-image: url(/assets/images/launch.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.75ex 1.75ex;
  height: 1.75ex;
  width: 1.75ex;
  content: "";
  display: inline;
  margin-top: .7ex;
  margin-left: 2px;
  padding-left: 1.75ex;
  vertical-align: middle;
}


@supports ((-webkit-mask:url()) or (mask:url())) {
  article li [href^="http"]:not([href*="nickwalker.us"],a.btn)::after,
  article p [href^="http"]:not([href*="nickwalker.us"],a.btn)::after,
  article h1 [href^="http"]:not([href*="nickwalker.us"],a.btn)::after,
  article h2 [href^="http"]:not([href*="nickwalker.us"],a.btn)::after{
    background: 0 0;
    background-color: rgba(0, 0, 0, 0);
    background-color: currentColor;
    -webkit-mask-image: url(/assets/images/launch.svg), linear-gradient(transparent, transparent);
    mask-image: url(/assets/images/launch.svg), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1.75ex 1.75ex;
    mask-size: 1.75ex 1.75ex;
  }
}

.anchor-link {
  padding: 0 .175rem;
  font-weight: 400;
  text-decoration: none;
  opacity: 0;
  transition: color 0.15s ease-in-out,opacity 0.15s ease-in-out;
}

.anchor-link::after {
  content: "#";
}

.anchor-link:focus, .anchor-link:hover, :hover > .anchor-link, :target .anchor-link {
  text-decoration: none;
  opacity: 1;
}


/**
 * Blockquotes
 */
blockquote {
  color: var(--lighter-text-color);
  border-left: 2px solid var(--light-background-color);
  padding-left: 1rem;

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */


pre,
code {
  font-size: 14px;
  border: 1px solid var(--light-background-color);
  border-radius: 3px;
  line-height: 1.3rem;
  font-family: "IBM Plex Mono", monospace;
}

code {
  padding: 1px 5px;
  background-color: var(--light-background-color);
}

pre {
  padding: 8px 12px;
  overflow-x: auto;
  background-color: var(--light-background-color);

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
  }
}

.container {
  max-width: 900px;
}

.badge {
  --bs-badge-font-weight: normal
}

.badge.text-bg-light {
  color: #666 !important;
}

ion-icon {
  margin-bottom: -3px;
}

.bibliography u {
  text-decoration-color: #999;
}

.filter-option::before {
  content: '#'
}